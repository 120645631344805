import { Injectable } from '@angular/core';
import { PrinterService } from './printer.service';
import { SostojbaService } from '../core/shared/sostojba.service';
import { IPrenosnica } from '../models/magacin/prenosnica';

@Injectable()
export class PrenosnicaPrinterService {

    constructor(public sostojbaService: SostojbaService,
        public printer: PrinterService) {
    }

    getPrenosnica(model: IPrenosnica) {
        const data = this.printer.getFinansiskiPregled();
        data.content[0].table = {
            headerRows: 2,
            widths: ['auto', '*', 'auto'],
            body: [
                [
                    this.printer.tableHeaderCell([
                            this.printer.tableHeaderTitle(`Преносница бр.${model.detali.brojNaDokument}`),
                            this.printer.tableHeaderSubtitle(`од магацин ${model.detali.odMagacin}`),
                            this.printer.tableHeaderSubtitle(`во магацин ${model.detali.voMagacin}`)
                        ],
                        3),
                    '',
                    ''
                ],
                [
                    this.printer.headerCellForText('Р.бр.'),
                    this.printer.headerCellForText('Назив'),
                    this.printer.headerCellForNumber('Количина')
                ]
            ],
            dontBreakRows: true
        };
        let i = 1;
        model.stavki.map(x => data.content[0].table.body.push([
            this.printer.regularCellText(`${i++}.`, 'right'),
            this.printer.regularCellText(x.artiklNaziv),
            this.printer.regularCellNumber(x.kolicina, 0)
        ]));
        data.content[0].table.body.push([
            this.printer.footerCellText('Вкупно:', 2), '', this.printer.footerCellNumber(model.vkupnoKolicina, 0)
        ]);
        return data;
    }

}
