import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatOptionSelectionChange } from '@angular/material/core';
import { SostojbaService } from '../../../core/shared/sostojba.service';
import { IProdavnica } from '../../../models/magacin/prodavnica';

@Component({
    selector: 'app-prodavnica-input',
    templateUrl: './prodavnica-input.component.html',
    styles: []
})
export class ProdavnicaInputComponent implements OnInit {

    @Input()
    parent: UntypedFormGroup;

    @Input()
    controlName: string;

    @Input()
    placeholderText = 'Продавница';

    @Input()
    requiredText = 'Продавницата е задолжителна';

    @Output()
    onSelect = new EventEmitter<any>();

    constructor(public sostojbaService: SostojbaService) {}

    ngOnInit(): void {}

    onSelected(event: MatOptionSelectionChange, prodavnica: IProdavnica) {
        if (event.source.selected) {
            this.onSelect.emit(prodavnica);
        }
    }

}
