import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IKalkulacija, IDetaliZaKalkulacija, IStavkaZaKalkulacija } from '../../models/magacin/kalkulacija';
import { BarkodPostModel } from '../../models/magacin/barkod-post';

class StavkaZakalkulacijaPostModel {
}

@Injectable()
export class KalkulacijaService {

    constructor(private http: HttpClient) {}

    getDokument(id: string): Observable<IKalkulacija> {
        return this.http.get<IKalkulacija>(`/MagacinApi/Kalkulacija/GetDokument/${id}`);
    }

    postDokument(id: number): Observable<IDetaliZaKalkulacija> {
        return this.http.get<IDetaliZaKalkulacija>(`/MagacinApi/Kalkulacija/PostDokument/${id}`);
    }

    postBarkod(stavka: BarkodPostModel): Observable<IStavkaZaKalkulacija> {
        return this.http.post<IStavkaZaKalkulacija>(`/MagacinApi/Kalkulacija/PostBarkod`, stavka);
    }

    editStavka(stavka: StavkaZakalkulacijaPostModel): Observable<IStavkaZaKalkulacija> {
        return this.http.post<IStavkaZaKalkulacija>(`/MagacinApi/Kalkulacija/EditStavka`, stavka);
    }

    postBarkodBroenje(stavka: BarkodPostModel): Observable<IStavkaZaKalkulacija> {
        return this.http.post<IStavkaZaKalkulacija>(`/MagacinApi/Kalkulacija/PostBarkodBroenje`, stavka);
    }

    resetBarkodBroenje(id: number): Observable<IStavkaZaKalkulacija> {
        return this.http.get<IStavkaZaKalkulacija>(`/MagacinApi/Kalkulacija/ResetBarkodBroenje/${id}`);
    }

    deleteStavka(id: number) { return this.http.get(`/MagacinApi/Kalkulacija/DeleteStavka/${id}`); }

    zatvoriKalkulacija(id: number) { return this.http.get(`/MagacinApi/Kalkulacija/Zatvori/${id}`); }

    azurirajKalkulacija(id: number) { return this.http.get(`/MagacinApi/Kalkulacija/Azuriraj/${id}`); }

}
