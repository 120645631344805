import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUvozVoMagacin } from '../../models/magacin/uvoz-magacin';
import { BrzVnesPostModel } from '../../models/magacin/brz-vnes';
import { IBrzVnesUvozMagacin } from '../../models/magacin/uvoz-magacin';
import { IStavkaUvozMagacin } from '../../models/magacin/uvoz-magacin';
import { StavkaPostModel } from '../../models/magacin/stavka';
import { StavkaPackPostModel } from '../../models/magacin/stavka';
import { IStavkaPackListaUvozMagacin } from '../../models/magacin/uvoz-magacin';

@Injectable()
export class UvozMagacinService {

    constructor(public httpClient: HttpClient) {}

    getDokument(id: string): Observable<IUvozVoMagacin> {
        return this.httpClient.get<IUvozVoMagacin>(`/MagacinApi/UvozMagacin/GetDokument/${id}`);
    }

    postStavka(stavka: StavkaPostModel): Observable<IStavkaUvozMagacin> {
        return this.httpClient.post<IStavkaUvozMagacin>(`/MagacinApi/UvozMagacin/PostStavka`, stavka);
    }

    postStavkaPack(stavka: StavkaPackPostModel): Observable<IStavkaPackListaUvozMagacin> {
        return this.httpClient.post<IStavkaPackListaUvozMagacin>(`/MagacinApi/UvozMagacin/PostStavkaPack`, stavka);
    }

    postBrzVnes(model: BrzVnesPostModel): Observable<IBrzVnesUvozMagacin> {
        return this.httpClient.post<IBrzVnesUvozMagacin>(`/MagacinApi/UvozMagacin/PostBrzVnes`, model);
    }

    editStavka(stavka: StavkaPostModel): Observable<IStavkaUvozMagacin> {
        return this.httpClient.post<IStavkaUvozMagacin>(`/MagacinApi/UvozMagacin/EditStavka`, stavka);
    }

    deleteStavka(id: number) { return this.httpClient.get(`/MagacinApi/UvozMagacin/DeleteStavka/${id}`); }
}
