import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDokumentZaDropdown } from '../../models/magacin/dialogs';

@Injectable()
export class DokumentiService {

    constructor(public httpClient: HttpClient) {}

    getKalkulacii(): Observable<IDokumentZaDropdown[]> {
        return this.httpClient.get<IDokumentZaDropdown[]>(`/MagacinApi/Dokumenti/GetKalkulacii`);
    }

    getNivelacii(): Observable<IDokumentZaDropdown[]> {
        return this.httpClient.get<IDokumentZaDropdown[]>(`/MagacinApi/Dokumenti/GetNivelacii`);
    }

    getUvozi(): Observable<IDokumentZaDropdown[]> {
        return this.httpClient.get<IDokumentZaDropdown[]>(`/MagacinApi/Dokumenti/GetUvozi`);
    }

    getPrenosnici(): Observable<IDokumentZaDropdown[]> {
        return this.httpClient.get<IDokumentZaDropdown[]>(`/MagacinApi/Dokumenti/GetPrenosnici`);
    }

}
