import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BarkodPostModel } from '../../models/magacin/barkod-post';
import { IPrenosnica, IDetaliZaPrenosnica, IStavkaZaPrenosnica } from '../../models/magacin/prenosnica';

@Injectable()
export class PrenosnicaService {

    constructor(private http: HttpClient) {}

    getDokument(id: string): Observable<IPrenosnica> {
        return this.http.get<IPrenosnica>(`/MagacinApi/Prenosnica/GetDokument/${id}`);
    }

    postDokument(id: number): Observable<IDetaliZaPrenosnica> {
        return this.http.get<IDetaliZaPrenosnica>(`/MagacinApi/Prenosnica/PostDokument/${id}`);
    }

    postBarkod(stavka: BarkodPostModel): Observable<IStavkaZaPrenosnica> {
        return this.http.post<IStavkaZaPrenosnica>(`/MagacinApi/Prenosnica/PostBarkod`, stavka);
    }

    deleteStavka(id: number) { return this.http.get(`/MagacinApi/Prenosnica/DeleteStavka/${id}`); }

    azurirajPrenosnica(id: number) { return this.http.get(`/MagacinApi/Prenosnica/Azuriraj/${id}`); }
}
