// ANGULAR
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SHARED_SERVICE_DECLARATIONS } from './shared/shared-services.declarations';
import { MAGACIN_SERVICE_DECLARATIONS } from './magacin/magacin-services.declarations';

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule
    ],
    providers: [
        DecimalPipe,
        DatePipe,
        SHARED_SERVICE_DECLARATIONS,
        MAGACIN_SERVICE_DECLARATIONS
    ]
})
export class CoreModule {
}
