import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IUvozVoProdavnica } from '../../models/magacin/uvoz-prodavnica';
import { StavkaPostModel } from '../../models/magacin/stavka';
import { IStavkaUvozProdavnicaPosted } from '../../models/magacin/uvoz-prodavnica';
import { BrzVnesPostModel } from '../../models/magacin/brz-vnes';
import { IBrzVnesUvozProdavnica } from '../../models/magacin/uvoz-prodavnica';
import { IStavkaUvozProdavnica } from '../../models/magacin/uvoz-prodavnica';

@Injectable()
export class UvozProdavnicaService {

    constructor(public httpClient: HttpClient) {}

    getDokument(uvozId: string, kalkulacijaId: string): Observable<IUvozVoProdavnica> {
        return this.httpClient.get<IUvozVoProdavnica>(
            `/MagacinApi/UvozProdavnica/GetDokument/${uvozId}/${kalkulacijaId}`);
    }

    postStavka(stavka: StavkaPostModel): Observable<IStavkaUvozProdavnicaPosted> {
        return this.httpClient.post<IStavkaUvozProdavnicaPosted>(`/MagacinApi/UvozProdavnica/PostStavka`,
            stavka);
    }

    postBrzVnes(model: BrzVnesPostModel): Observable<IBrzVnesUvozProdavnica> {
        return this.httpClient.post<IBrzVnesUvozProdavnica>(`/MagacinApi/UvozProdavnica/PostBrzVnes`, model);
    }

    editStavka(stavka: StavkaPostModel): Observable<IStavkaUvozProdavnica> {
        return this.httpClient.post<IStavkaUvozProdavnica>(`/MagacinApi/UvozProdavnica/EditStavka`, stavka);
    }

    deleteStavka(stavkaId: number, kalkulacijaId: number) {
        return this.httpClient.get(`/MagacinApi/UvozProdavnica/DeleteStavka/${stavkaId}/${kalkulacijaId}`);
    }
}
