import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SostojbaService } from '../../../core/shared/sostojba.service';

@Component({
    selector: 'app-boja-input',
    templateUrl: './boja-input.component.html',
    styles: []
})
export class BojaInputComponent {

    @Input()
    parent: FormGroup;

    @Input()
    controlName: string;

    @Input()
    prefiks = '';

    @Input()
    placeholderText = 'Боја';

    @Input()
    requiredText = 'Бојата е задолжителна';

    @Output()
    onSelect = new EventEmitter<any>();

    constructor(public sostojbaService: SostojbaService) {}

}
