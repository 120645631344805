// ANGULAR
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInGuard } from '../core/shared/logged-in.guard';

// Components
const routes: Routes = [
    {
        path: '',
        redirectTo: 'Home',
        pathMatch: 'full'
    },
    {
        path: 'Home',
        loadChildren: () => import('../home/home.module').then(m => m.HomeModule)
    },
    {
        path: 'Magacin',
        loadChildren: () => import('../magacin/magacin.module').then(m => m.MagacinModule),
        canActivate: [LoggedInGuard]
    }
];

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule {
}
