import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IDopunaPoVidResult } from '../../models/magacin/dopuna';
import { DopunaPostModel } from '../../models/magacin/dopuna';
import { IDopunaObjekt } from '../../models/magacin/dopuna';
import { DopunaPoVidPostModel } from '../../models/magacin/dopuna';
import { IProverkaNaArtikl } from '../../models/magacin/proverka-na-artikl';
import { PregledPoKategoriiPostModel } from '../../models/magacin/pregled-po-kategorii';
import { IPregledPoKategoriiLokacija } from '../../models/magacin/pregled-po-kategorii';

@Injectable()
export class IzvestaiService {

    constructor(private http: HttpClient) {}

    getDopuna(model: DopunaPostModel): Observable<IDopunaObjekt[]> {
        return this.http.post<IDopunaObjekt[]>(`/MagacinApi/Izvestai/GetDopuna`, model);
    }

    getDopunaPoVid(model: DopunaPoVidPostModel): Observable<IDopunaPoVidResult> {
        return this.http.post<IDopunaPoVidResult>(`/MagacinApi/Izvestai/GetDopunaPoVid`, model);
    }

    getProverka(barkod: string): Observable<IProverkaNaArtikl> {
        return this.http.get<IProverkaNaArtikl>(`/MagacinApi/Izvestai/GetProverka/${barkod}`);
    }

    getPregledPoKategorii(model: PregledPoKategoriiPostModel): Observable<IPregledPoKategoriiLokacija[]> {
        return this.http.post<IPregledPoKategoriiLokacija[]>(`/MagacinApi/Izvestai/GetPregledPoKategorii`, model);
    }

}
