import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SostojbaService } from '../../../core/shared/sostojba.service';

@Component({
    selector: 'app-sostav-input',
    templateUrl: './sostav-input.component.html',
    styles: []
})
export class SostavInputComponent {

    @Input()
    parent: FormGroup;

    @Input()
    controlName: string;

    @Input()
    placeholderText: string;

    constructor(public sostojbaService: SostojbaService) {}

}
