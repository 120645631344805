<div [formGroup]="parent"
     class="mdc-layout-grid__inner">
    <mat-form-field class="mdc-layout-grid__cell--span-12">
        <span matPrefix>{{ prefiks }}</span>
        <mat-select [formControlName]="controlName"
                    [placeholder]="placeholderText">
            <mat-option *ngFor="let boja of sostojbaService.sostojba.boi"
                        [value]="boja.id">
                {{ boja.kod }} - {{ boja.glavnaBoja }} - {{ boja.naziv }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="parent.get(controlName).hasError('required')">
            {{ requiredText }}
        </mat-error>
    </mat-form-field>
</div>
