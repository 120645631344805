import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SostojbaService } from '../../../core/shared/sostojba.service';

@Component({
    selector: 'app-naziv-input',
    templateUrl: './naziv-input.component.html',
    styles: []
})
export class NazivInputComponent {

    @Input()
    parent: UntypedFormGroup;

    @Input()
    controlName: string;

    @Input()
    placeholderText = 'Назив';

    constructor(public sostojbaService: SostojbaService) {}

}
