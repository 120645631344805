import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SostojbaService } from '../../../core/shared/sostojba.service';

@Component({
    selector: 'app-velicina-input',
    templateUrl: './velicina-input.component.html',
    styles: []
})
export class VelicinaInputComponent {

    @Input()
    parent: UntypedFormGroup;

    @Input()
    controlName: string;

    @Input()
    placeholderText: string;

    constructor(public sostojbaService: SostojbaService) {}

}
