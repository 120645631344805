import { Injectable } from '@angular/core';
import { PrinterService } from './printer.service';
import { IKalkulacija } from '../models/magacin/kalkulacija';
import { SostojbaService } from '../core/shared/sostojba.service';

@Injectable()
export class KalkulacijaPrinterService {

    constructor(public sostojbaService: SostojbaService,
        public printer: PrinterService) {
    }

    getKalkulacija(model: IKalkulacija) {
        const data = this.printer.getFinansiskiPregled();
        data.content[0].table = {
            headerRows: 2,
            widths: ['auto', '*', 'auto', 'auto', 'auto'],
            body: [
                [
                    this.printer.tableHeaderCell([
                            this.printer.tableHeaderTitle(`Калкулација бр.${model.detali.brojNaKalkulacija}`),
                            this.printer.tableHeaderSubtitle(`за продавница: ${model.detali.prodavnicaNaziv}`)
                        ],
                        5),
                    '',
                    '',
                    '',
                    ''
                ],
                [
                    this.printer.headerCellForText('Р.бр.'),
                    this.printer.headerCellForText('Назив'),
                    this.printer.headerCellForNumber('Количина'),
                    this.printer.headerCellForNumber('Продажна цена'),
                    this.printer.headerCellForNumber('Вкупно')
                ]
            ],
            dontBreakRows: true
        };
        let i = 1;
        model.stavki.map(x => data.content[0].table.body.push([
            this.printer.regularCellText(`${i++}.`, 'right'),
            this.printer.regularCellText(x.artiklNaziv),
            this.printer.regularCellNumber(x.kolicina, 0),
            this.printer.regularCellNumber(x.prodaznaCena, 0),
            this.printer.regularCellNumber(x.vkupnoProdazna, 0)
        ]));
        data.content[0].table.body.push([
            this.printer.footerCellText('Вкупно:', 2), '', this.printer.footerCellNumber(model.vkupnoKolicina, 0), '',
            this.printer.footerCellNumber(model.vkupnoProdaznaCena, 0)
        ]);
        data.content[0].table.body.push([
            {
                table: {
                    headerRows: 2,
                    widths: ['*', '*', '*'],
                    body: [
                        [' ', ' ', ' '],
                        [{ text: 'Испратил:', alignment: 'center' }, ' ', { text: 'Примил:', alignment: 'center' }],
                        [' ', ' ', ' '],
                        [
                            { text: '_____________________', alignment: 'center' }, ' ',
                            { text: '_____________________', alignment: 'center' }
                        ]
                    ],
                    dontBreakRows: true
                },
                layout: 'noBorders',
                colSpan: 5
            }, ' ', ' ', ' ', ' '
        ]);
        return data;
    }

}
